import React from "react"; 
// import {useEffect} from 'react';
import "./Domainandhosting.css";
import Demo from "../../Components/Parts/Demobar/Demobar1.jsx"
const domainandhosting = () => {
    // useEffect(() =>{
    //     document.title="best web hosting and domain registration providers in Islamabad, Lahore, Sialkot, Faisalabad, Pakistan &#8211; Quaid Soft | ERP Software Company In Pakistan";
    // },[]);
  return (
   <>
   <div className=" page-header  py-2 mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center ">
            <h3 className="text-white animated slideInDown mb-4 mt-5">Domain And Hosting</h3>
        </div>
    </div>
    <div className="container-xxl courses py-2">
<div className="row p-4 ">
<h2 className="text-center">Hosting Services and Domain Packages by Quaid Soft</h2>
    <div className='domdispflex'>
    <div className="w-100 p-1">
        <h4 className="animated slideInDown mb-4">Are You Still Looking For Domain Name?</h4>
        <p className="domaintext" > 
            To accomplish your DNS and online visibility needs, At Quaid Soft, we are available as domain registrars & PKNIC resellers in Pakistan, providing top-notch services for inland and international clients. Quaid Soft is a renowned domain registrar, based in Lahore, with 5 years of registration experience in Pakistan. We are not just claiming but also providing fully reliable and the cheapest domain registration service as well as after-sale services as compared to other companies. Your first step is to establish a web presence by acquiring a suitable and most appropriate website name, an online name that must be easy to remember, and its keywords must be in accordance with your business nature and as per layman mentality to secure an appropriate base for your business worldwide. We are the most energetic, advanced, leading, and convenient website domain registration company in Pakistan.</p>
    </div>
    <div className="proimgw p-2">
        <img className="b11" src={process.env.PUBLIC_URL + '/Images/Products/domain.jpg'} alt="Best Web Hosting And Domain Registration Providers In Islamabad, Lahore, Sialkot, Faisalabad"/>      
    </div>
    </div>
</div>


<hr/>

    <div className="row p-4">
        <h3>Domain Services</h3>
        <div className="domdispflex">
            <div className="w-100 p-1" >
                <p className="domaintext">
                    Welcome to Quaid Soft, your go-to source for dependable hosting services and adaptable domain packages. Whether you're initiating a new website, transferring an existing one, or aiming to upgrade your current hosting arrangement, we have tailored solutions for your needs. Our commitment is to provide reliable and secure hosting, ensuring your online presence thrives seamlessly. At Quaid Soft, we understand the importance of a robust online platform, and our hosting services are designed to meet the diverse requirements of businesses and individuals. Explore our flexible domain packages that cater to different scales and types of websites. Trust Quaid Soft to be your reliable partner in the digital landscape, delivering top-notch hosting solutions with a focus on performance and stability. Join the countless satisfied customers who have experienced the excellence of Quaid Soft's hosting services. Your success online begins with Quaid Soft, where reliability meets innovation in hosting solutions.</p>
    
            </div>
            <div className="proimgw p-2">
             <img className="b11" src={process.env.PUBLIC_URL + '/Images/Products/domain-regestration.jpg'} alt="Best Web Hosting And Domain Registration Providers In Islamabad, Lahore, Faisalabad, Pakistan"/>
            </div>
        </div>
    </div>
    
    <div className="row p-4 ppqq" data-wow-delay="0.4s">
        <div className="justify-content-between domdispflex" >
            <div className="bounceInLeft wow ookdd"data-wow-delay="0.4s">
                <div className="w-100 hhqq" > <h4>Domain</h4></div>
                
            <p>.com .pk <br/><br/>
    
                .pk<br/><br/>
                
                .com<br/><br/>
                
                Other Domain<br/><br/>
                
                Country Extension Domain</p>
            </div>
            <div className="bounceInUp wow ookdd" data-wow-delay="0.5s">
                <div className="w-100 serv"> <h4>Server</h4></div>
                
            <p>PKNIC <br/><br/>
    
                PKNIC<br/><br/>
                
                International<br/><br/>
                
                International<br/><br/>
                
                International</p>
            </div>
            <div className="bounceInup wow ookdd" data-wow-delay="0.6s">
                <div className="w-100 ssss"> <h4>Renew Period </h4></div>
                
            <p>2 Year <br/><br/>
    
             2 Year<br/><br/>
                
             1 Year<br/><br/>
              
             1 Year<br/><br/>
                
             1 Year</p>
            </div>
</div>
</div>
    <hr/>
    <div className='row p-4'>
     <h3>Hosting Services</h3>
    <div className="domdispflex" >
        <div className="w-100 p-1">
            <p className="domaintext">
                Welcome to Quaid Soft, your go-to source for dependable hosting services and adaptable domain packages. Whether you're initiating a new website, transferring an existing one, or aiming to upgrade your current hosting arrangement, we have tailored solutions for your needs. Our commitment is to provide reliable and secure hosting, ensuring your online presence thrives seamlessly. At Quaid Soft, we understand the importance of a robust online platform, and our hosting services are designed to meet the diverse requirements of businesses and individuals. Explore our flexible domain packages that cater to different scales and types of websites. Trust Quaid Soft to be your reliable partner in the digital landscape, delivering top-notch hosting solutions with a focus on performance and stability. Join the countless satisfied customers who have experienced the excellence of Quaid Soft's hosting services. Your success online begins with Quaid Soft, where reliability meets innovation in hosting solutions.</p>

        </div>
        <div className="proimgw p-2">
         <img className="b11" src={process.env.PUBLIC_URL + '/Images/Products/hosting-service.jpg'} alt="Best Web Hosting And Domain Registration Providers In Islamabad, Lahore, Faisalabad, Pakistan"/>

        </div>

    </div>
    </div>
    <div className="row p-4 ppqq" data-wow-delay="0.4s">
        <div  className="justify-content-between flex1" >
            <div className=" p-3 bounceInLeft wow ookdd"data-wow-delay="0.4s">
                <div className="w-100 serv" > <h4>Feature</h4></div>
                
            <p>Disk Space <br/><br/>
        
                POP3 Emails Accounts<br/><br/>
                
                Database<br/><br/>
                
                FTP Accounts<br/><br/>
                
                Sub Domains</p>
                
            </div>
            <div className=" p-3 bounceInUp wow ookdd" data-wow-delay="0.5s">
                <div className="w-100 serv"  > <h4>Basic</h4></div>
                
            <p>1GB <br/><br/>
        
                5<br/><br/>
                
                1<br/><br/>
                
                10<br/><br/>
                
                10</p>
            </div>
            <div className=" p-3 bounceInup wow ookdd" data-wow-delay="0.6s">
                <div className="w-100 serv" > <h4>Business</h4></div>
                
            <p>5GB <br/><br/>
        
             Unlimited<br/><br/>
                
             2<br/><br/>
              
             15<br/><br/>
                
             15</p>
            </div>
            <div className=" p-3 bounceInup wow ookdd" data-wow-delay="0.6s">
                <div className="w-100 serv" > <h4>Premium</h4></div>
                
            <p>Unlimited <br/><br/>
        
             Unlimited<br/><br/>
                
             5<br/><br/>
              
             30<br/><br/>
                
             Unlimited</p>
            </div>
            
        </div></div>
        <Demo/>
        </div>



   </>
  )
}

export default domainandhosting