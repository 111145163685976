import React from 'react'
import "./Applyjob.css"
const Applyjob = () => {
  return (
    <>
     <div className=" page-header py-2 mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center ">
            <h3 className="text-white animated slideInDown mb-4 mt-5">Apply Now</h3>
        </div>
    </div>
    
    </>
  )
}

export default Applyjob