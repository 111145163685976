import React, { useRef, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom';
import "./Contactus.css";

const Contactus = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        setIsSubmitting(true); // Set isSubmitting to true to disable submit button
        emailjs
            .sendForm('service_mz0s14i', 'template_4bbubd2', form.current, {
                publicKey: 'mgiJEQH-aUigd3KVJ',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                    handleSubmit();
                },
                (error) => {
                    console.log('FAILED...', error.text);
                    setIsSubmitting(false); // Reset isSubmitting to false on failure
                },
            );
    };
    const navigate = useNavigate();

    const handleSubmit = () => {
        navigate('/');
        alert('Your Query has been Received. We will contact you shortly. Thanks!');
        scrollToTop();
        setIsSubmitting(false); // Reset isSubmitting to false after successful submission
    };
    useEffect(() =>{
        document.title="contact us &#8211; Quaid Soft | ERP Software Company In Pakistan";
    },[]);
    return (
        <>
            <div class=" page-header py-2 mt-5 wow fadeIn" data-wow-delay="0.1s">
                <div class="container text-center ">
                    <h3 class="text-white animated slideInDown mb-4 mt-5">Contact Us</h3>
                </div>
            </div>

            <div class=" courses container-xxl">
                <div class="row p-4">
                    <div class="www">
                        <div class="col text-center mt-5 p-2 W-100  ">
                            <p className='perag'>If You Have Any Query, Please Contact Us</p>
                            <p class="mb-2 pp11">Where client satisfaction is our priority. Feel free to reach out to us for any inquiries regarding software proposals or detailed information about our Real Estate ERP and other modules. Our dedicated Sales Team is here to assist you, ensuring a seamless experience as you explore Real Estate Tailored & Customized Software solutions for your Project's business needs. 
                                Contact us today, and let's embark on a journey to elevate your software experience together.</p>
                        </div>
                        <div class="col p-2 W-100 ">
                            <form class="mt-2" ref={form} onSubmit={sendEmail}>

                                <div class="formGroup">
                                    <label class="label1">Name</label>
                                    <input type="text" name="contact_name" id="name" placeholder="Your Name" autoComplete="off"
                                        required />
                                </div>

                                <div class="formGroup">
                                    <label class="label1">Email</label>
                                    <input type="email" name="contact_email" id="email" placeholder="Email ID" autoComplete="off"
                                        required />
                                </div>

                                <div class="formGroup">
                                    <label class="label1">Number</label>
                                    <input type="text" pattern="^03\d{9}$" title="Please enter a valid phone number starting with '03'" name="contact_number" placeholder="03001234..." autoComplete="off"
                                        required />
                                </div>

                                <div class="formGroup">
                                    <label class="label1">Services</label>
                                    <select class="opti" name="contact_service" required="">
                                        <option class="opti" selected disabled hidden>N/A</option>
                                        <option class="opti">Software Development</option>
                                        <option class="opti">Web Development</option>
                                        <option class="opti">Mobile App Development</option>
                                        <option class="opti">Project Consultancy</option>
                                        <option class="opti">Domain & Hosting</option>
                                        <option class="opti">Hardware & Network Services</option>
                                        <option class="opti">Shoots & Tv Commercial</option>
                                        <option class="opti">CCTV Cameras</option>
                                    </select>
                                </div>

                                <div class="formGroup">
                                    <label class="label1">Products</label>
                                    <select class="opti" name="contact_project" required>
                                        <option value="" class="opti" selected disabled hidden>N/A</option>
                                        <option class="opti">Lead Management System</option>
                                        <option class="opti">Real Estate ERP</option>
                                        <option class="opti">Real Estate Mobile Application</option>
                                        <option class="opti">Real Estate CRM-Leads</option>
                                        <option class="opti">Open-Form-Trading</option>
                                        <option class="opti">Housing-Scheme-Software-Sales</option>
                                        <option class="opti">Building-Management-Software-Sales</option>
                                        <option class="opti">Real-Estate-Software-Sales</option>
                                        <option class="opti">Property-Management-Software-Sales</option>
                                        <option class="opti">Construction-Management-Software</option>
                                        <option class="opti">Accounts-Management-Software</option>
                                        <option class="opti">Society-Billing-Management-Software</option>
                                        <option class="opti">Land-Acquisition-Management</option>
                                        <option class="opti">Computerized-Plots-Balloting</option>
                                        <option class="opti">Marketing-Dealers-Management</option>
                                        <option class="opti">Biometric-Registration-Verification</option>
                                        <option class="opti">Sales-Recovery-Follow-up</option>
                                    </select>
                                </div>

                                <div class="formGroup">
                                    <label class="label1">Message</label>
                                    <textarea name="contact_message" placeholder="Message" required></textarea>
                                </div>

                                <div class="formGroup">
                                    <input class="bttnn" name="submit" type="submit" value="Send" disabled={isSubmitting} />
                                </div>


                            </form>



                            </div>
                </div>
            </div>

            <hr />
            <div class="container-fluid">
                <iframe class="position-relative w-100 h-50 ppii"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3401.365811882959!2d74.34099181079604!3d31.51411134734438!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x690a0188864e0af9%3A0x254b6898960ecf36!2sQuaid%20Soft%20%C2%AE%20%7C%20A%20Real%20Estate%20Software%20-%20Housing%20Scheme%20Software%20Company%20in%20Lahore%20Pakistan!5e0!3m2!1sen!2s!4v1701072931173!5m2!1sen!2s"
                    frameborder="0" allowfullscreen="" aria-hidden="false"
                    tabindex="0"></iframe>
            </div>
        </div>
    </>
  );
}

export default Contactus;