import {useEffect} from 'react';
import'./Team.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
const Team = () => {
    useEffect(() => {
        AOS.init();
      }, [])
  return (
    <>    <div className="container-xxl py-2 " >
        <div className="container">
        <h3 className="mb-0">CEO's Message</h3>
            <div className="row g-0 team-items justify-content-center">
                <div className=" col-md-6 p-2" data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom">
                    <div className="position-relative ">  
                            <p className="mb-4 mt-2 pjust" >Quaid Soft is purely a real estate software automation company. Its management (Mian Azhar and Muhammad Mustafa) have 15+ years of experience in real estate automation, serving approximately 600+ real estate housing and high-rise building projects in all provinces of Pakistan. Its success story is carved by the untiring hard work, dedication, and adherence of the Quaid Soft team to its cause. For our clients, Quaid Soft is a name that stands for its assistance to customers, commitment to work, long-term relationships, customer care, customized solutions, application integrity, product and domain knowledge, retainer ship services, and competitive prices. Quaid Soft was properly registered as a software and IT company in 2015. We have vast experience in customized real estate software development, web development, business automation, and other corporate sectors, with a broad vision.</p>
                    </div>
                </div>
                <div className="col-lg-3 mt-2 col-md-6 wow fadeInUp" data-wow-delay="0.1s" data-aos="zoom-in-up">
                    <div className="team-item position-relative">
                        <div className="position-relative">
                            <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/Team/Mian Azhar (Managing Director Quaidsoft).jpg'} alt=""/>
                            <div className="team-social text-center">
                                <Link className="btn btn-square btn-outline-primary border-2 m-1" target="_blank" to="https://www.facebook.com/mianazharhussain.pk"><i className="fab fa-facebook-f"></i></Link>
                                <Link className="btn btn-square btn-outline-primary border-2 m-1" target="_blank" to="https://twitter.com/azharhussain_pk"><i className="fab fa-twitter"></i></Link>
                                <Link className="btn btn-square btn-outline-primary border-2 m-1" target="_blank" to="https://www.instagram.com/azharhussain_pk/"><i className="fab fa-instagram"></i></Link>
                            </div>
                        </div>
                        <div className="bg-light text-center p-4">
                            <h5 className="mt-2 text-black">Mian Azhar Hussain</h5>
                            <span>Managing Director</span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 mt-2 col-md-6 wow fadeInUp" data-wow-delay="0.2s" data-aos="zoom-in-up">
                    <div className="team-item position-relative">
                        <div className="position-relative">
                            <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/Team/Mustafa (CEO QuaidSoft).jpg'} alt=""/>
                            <div className="team-social text-center">
                                <Link className="btn btn-square btn-outline-primary border-2 m-1" target="_blank" to="https://www.facebook.com/yehchees"><i className="fab fa-facebook-f"></i></Link>
                                <Link className="btn btn-square btn-outline-primary border-2 m-1" target="_blank" to="#"><i className="fab fa-twitter"></i></Link>
                                <Link className="btn btn-square btn-outline-primary border-2 m-1" target="_blank" to="https://www.instagram.com/yehchees/?igsh=MWQ0bm11aGY3MGlyeg%3D%3D"><i className="fab fa-instagram"></i></Link>
                            </div>
                        </div>
                        <div className="bg-light text-center p-4">
                            <h5 className="mt-2 text-black">Muhammad Mustafa</h5>
                            <span>Chief Executive Officer</span>
                        </div>
                    </div>
                </div>
            
            </div>
        </div>
        <hr/>
        <div className="text-center mx-auto mb-5 wow fadeInUp w-500" data-wow-delay="0.1s" >
            <h3 className="mb-4">OUR TEAM</h3>
        </div>  
        <div className="container" data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom" >
            <div className="text-center mx-auto mb-5 wow fadeInUp w-500" data-wow-delay="0.1s" >
                <h6 className="text-primary text-uppercase mb-2"></h6>
                <h1 className="display-6 mb-4"></h1>
            </div>
            <div className="row g-0 team-items justify-content-center">
                <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="team-item position-relative">
                        <div className="position-relative">
                            <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/Team/malik-khurram.jpg'} alt=""/>
                            <div className="team-social text-center">
           <h5 >Malik Khuram<br/><span className="team1">System Analyst</span></h5> 
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
                    <div className="team-item position-relative">
                        <div className="position-relative">
                            <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/Team/Shiraz Ali.jpg'} alt=""/>
                            <div className="team-social text-center">
                         <h5 >Shiraz Ali <br/><span className="team1">General Manager (KPK)</span></h5> 
                            </div>
                        </div>
                       
                    </div>
                </div>


                <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="team-item position-relative">
                        <div className="position-relative">
                            <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/Team/waseem-zahid.jpg'} alt=""/>
                            <div className="team-social text-center">
                                <h5 >Waseem Zahid <br/><span className="team1">General Manager Sales (KSA)</span></h5> 
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                    <div className="team-item position-relative">
                        <div className="position-relative">
                            <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/Team/sarwat-raheem_quaidsoft.jpg'} alt="sarwat-raheem(quaidsoft)"/>
                            <div className="team-social text-center">
                                <h5 >Sarwat Rahim<br/><span className="team1">General Manager Sales (Punjab)</span></h5>    
                            </div>
                        </div>
                    </div>
                </div>
            
            </div>
        </div>

        
        <div className="container"data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom" >
            <div className="text-center mx-auto mb-5 wow fadeInUp w-500" data-wow-delay="0.1s" >
                <h6 className="text-primary text-uppercase mb-2"></h6>
                <h1 className="display-6 mb-4"></h1>
            </div>
            <div className="row g-0 team-items justify-content-center">
                <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="team-item position-relative">
                        <div className="position-relative">
                            <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/Team/zeeshan-tariq.jpg'} alt=""/>
                            <div className="team-social text-center">
                                <h5 >Zeeshan Tariq<br/><span className="team1">Software / Web Developer</span></h5>    
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="team-item position-relative">
                        <div className="position-relative">
                            <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/Team/atif-shahzad.jpg'} alt=""/>
                            <div className="team-social text-center">
                                <h5 >Atif Shahzad<br/><span className="team1">Software Engineer</span></h5> 
                            </div>
                        </div>
                    </div>
                </div>


                <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="team-item position-relative">
                        <div className="position-relative">
                            <img className="img-fluid"src={process.env.PUBLIC_URL + '/Images/Team/Talha Mehmood(web developer).jpg'} alt=""/>
                            <div className="team-social text-center">
                                <h5 >Talha Mehmood<br/><span className="team1">Sr. Web Developer/SEO Expert</span></h5> 
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 wow fadeInUp rad"  data-wow-delay="0.7s">
                    <div className="team-item position-relative">
                        <div className="position-relative">
                            <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/Team/Muhammad Umair.jpg'} alt=""/>
                            <div className="team-social text-center">
                                <h5 >Muhammad Umair<br/><span className="team1">Mobile App Developer</span></h5> 
                            </div>
                        </div>
                    </div>
                </div>
            
            </div>
        </div>

<div className="container" data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom">
    <div className="text-center mx-auto mb-5 wow fadeInUp w-500" data-wow-delay="0.1s" >
        <h6 className="text-primary text-uppercase mb-2"></h6>
        <h1 className="display-6 mb-4"></h1>
    </div>
    <div className="row g-0 team-items justify-content-center">
        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
            <div className="team-item position-relative">
                <div className="position-relative">
                    <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/Team/Irtaza Quaidsoft.png'} alt=""/>
                    <div className="team-social text-center">
                        <h5 >M. Irtaza Akhtar<br/><span className="team1">Sr. SQA / Support Engr.</span></h5> 
                    </div>
                </div>
            </div>
        </div>

        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
            <div className="team-item position-relative">
                <div className="position-relative">
                    <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/Team/asadkasana.png'} alt="asadkasana"/>
                    <div className="team-social text-center">
                        <h5 >M. Asad Kissana<br/><span className="team1">Software Engineer</span></h5> 
                    </div>
                </div>
            </div>
        </div>


        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
            <div className="team-item position-relative">
                <div className="position-relative">
                    <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/Team/Usmanquaidsoft.jpg'} alt=""/>
                    <div className="team-social text-center">
                        <h5 >Muhammad Usman<br/><span className="team1">Sr. SQA / Support Engr.</span></h5> 
                    </div>
                </div>
            </div>
        </div>

        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
            <div className="team-item position-relative">
                <div className="position-relative">
                    <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/Team/Aqsad.jpg'} alt=""/>
                    <div className="team-social text-center">
                        <h5>Aqsad Javeed<br/><span className="team1">Sr. Software Engineer</span></h5>
                    </div>
                </div>
            </div>
        </div>
    
    </div>
</div>

<div className="container" data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom">
    <div className="text-center mx-auto mb-5 wow fadeInUp w-500" data-wow-delay="0.1s" >
        <h6 className="text-primary text-uppercase mb-2"></h6>
        <h1 className="display-6 mb-4"></h1>
    </div>
    <div className="row g-0 team-items justify-content-center">
        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
            <div className="team-item position-relative">
                <div className="position-relative">
                    <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/Team/Mahmood_baig.jpg'} alt=""/>
                    <div className="team-social text-center">
                        <h5 >Mahmood Baig<br/><span className="team1">SQA / Support Engr.</span></h5> 
                    </div>
                </div>
            </div>
        </div>

        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
            <div className="team-item position-relative">
                <div className="position-relative">
                    <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/Team/sarwat-raheem_quaidsoft.jpg'} alt="asadkasana"/>
                    <div className="team-social text-center">
                        <h5 >Khushbakht Mazhar<br/><span className="team1">Sales Executive</span></h5> 
                    </div>
                </div>
            </div>
        </div>


        {/* <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
            <div className="team-item position-relative">
                <div className="position-relative">
                    <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/Team/Usmanquaidsoft.jpg'} alt=""/>
                    <div className="team-social text-center">
                        <h5 >Muhammad Usman<br/><span className="team1">Sr. SQA / Support Engr.</span></h5> 
                    </div>
                </div>
            </div>
        </div>

        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
            <div className="team-item position-relative">
                <div className="position-relative">
                    <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/Team/Aqsad.jpg'} alt=""/>
                    <div className="team-social text-center">
                        <h5>Aqsad Javeed<br/><span className="team1">Sr. Software Engineer</span></h5>
                    </div>
                </div>
            </div>
        </div> */}
    
    </div>
</div>

    </div>
    </>

  );
}

export default Team;