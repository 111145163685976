import {useEffect}  from 'react'
import "./Home.css";

import Hero from "../../Components/Parts/Hero/Hero";
import Aboutus from "../../Components/Parts/Aboutus/Aboutus";
import Services from "../../Components/Parts/Services/Services";
import Products from '../../Components/Parts/Products/Products';
import Demobar from '../../Components/Parts/Demobar/Demobar';
import Team from '../../Components/Parts/Team/Team.jsx';
import Contactus from '../../Components/Parts/Contactus/Contactus.jsx';

const Home = () => {
  useEffect(() =>{
    document.title="Quaid Soft | ERP Software Company In Pakistan &#8211; A Software Development &amp; IT CO";
},[]);
  return (
    <>
    <div className='hero1'>
    <Hero/>
    </div >
    <div className='courses container-xxl'>
    <div className='p-4'>
    <div class="text-center" >
      <h2 className='h3e'>About Us</h2>    
       </div>
      <Aboutus/>
    </div>
    <hr className='row'/>
    <div className='p-4'>
      <Services/>
    </div>
    <hr className='row'/>
    <div className="container-xxl my-2 py-01 pb-2 p-2">
                <div  className="wow fadeInUpp-1 erpimg "  data-wow-delay="0.3s"><h3>ERP Modules For Housing & Buildings Projects</h3>
                </div>
                     <img  className="imageed mx-auto imgg" src={process.env.PUBLIC_URL + '/Images/Sliders/Property-software-real-estate-software-Pakistan-property-management-software-Pakistan-sales-management-software-Pakistan-property-management-software-real-estate-software.jpg'}  alt="Pakistan Top Real Estate Software Company, Top Ten Real Estate Software, Developers Management System, Building Sales Management System, Society Billing System, Maintenance Management System, Construction Management System, Open Form Trading System, Open Form QR Code Printing, QR Code Printing on Open Form, Real Estate File Trading System, Islamabad Housing Scheme Software"/>
        </div>

        <hr className='row'/>
      <h2 class="text-center">Products</h2>    
      <Products/>
    <div className='p-2 mt-0'>
      <Demobar/>
    </div>
    <div className='p-2'>
      <Team/>
    </div>
    <hr className='row'/>
    <div className='p-2 '>
    <div class="text-center" >
      <h2 className='h3e'>Contact Us</h2>    
       </div>
      <Contactus/>
    </div>
    
    
    </div>
    </>
  )
}

export default Home