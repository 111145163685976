import React from 'react'
import { Routes, Route } from "react-router-dom";
import"./App.css";
import Home from "./Pages/Home/Home.jsx";
import Navbar from './Components/Navbar/Navbar1.jsx';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
import Footer from './Components/Footer/Footer.jsx';
import Aboutus from './Pages/Aboutus/Aboutus.jsx';
import Team from './Pages/Team/Team.jsx';
import Product from './Pages/Products/Product.jsx';
import Contactus from './Pages/Contactus/Contactus.jsx';
import Popups from './Pages/Home/Popups.jsx';
import Softwarede from './Pages/Servicespages/Softwaredevelopment.jsx';
import Webdevlo from './Pages/Servicespages/Webdevelopment.jsx';
import Careers from './Pages/Careers/Careers.jsx';
import Mobappdev from './Pages/Servicespages/Mobileapplication.jsx';
import Projc from './Pages/Servicespages/Projectconsultancy.jsx';
import Domhost from './Pages/Servicespages/Domainandhosting.jsx';
import Digmak from './Pages/Servicespages/Digitalmarketing.jsx';
import Hardnet from './Pages/Servicespages/Hardwareandnetworking.jsx';
import Shoottv from './Pages/Servicespages/Shootsandtvcommercials.jsx';
import CCTV from './Pages/Servicespages/Cctvcameras.jsx';
import Gallery from './Pages/Gallery/Gallery.jsx';
import Blog from './Pages/Blog/Blog.jsx';
import Support from './Pages/Support/Support.jsx';
import Sitemap from './Pages/Sitemap/Sitemap.jsx';
import Realcrm from './Pages/Productspages/Realestatecrmleadsmanagementsystem.jsx';
import Oprnfo from './Pages/Productspages/Openformtradingwithqrforhousingbuilding.jsx';
import Housins from './Pages/Productspages/Housingschemesoftwareinpakistan.jsx';
import Billing from './Pages/Productspages/Buildingsalesmanagementsoftwaresales.jsx';
import Restsoft from './Pages/Productspages/Realestatesoftwareinpakistan.jsx';
import Propmsystem from './Pages/Productspages/Propertymanagementsystem.jsx';
import Conststem from './Pages/Productspages/Constructionmanagementsoftware.jsx';
import Accountm from './Pages/Productspages/Accountsmanagementsoftware.jsx';
import Billingm from './Pages/Productspages/Billingmanagementsoftware.jsx';
import Landac from './Pages/Productspages/Landacquisitionmanagementsystem.jsx';
import CompPB from './Pages/Productspages/Computerizedplotballotingforhousingscheme.jsx';
import Mkdcm from './Pages/Productspages/MarketingDealersCommissionManagement.jsx';
import Biom from './Pages/Productspages/Biometricregistrationsoftware.jsx';
import Brandsms from './Pages/Productspages/Brandsms.jsx';
import Projsal from './Pages/Productspages/Projectsalesrecoveryfollowup.jsx';
import Prot from './Pages/Protfolio/Protfolio.jsx';
import Whatsapp from './Components/Whatsapp/Whatsapp.jsx';
import ApplyNow from './Pages/Applyjob/Applyjob.jsx';
import Toper from './Components/Toper/Toper.jsx';
const App = () => {
  return (
    <>
    <Navbar/>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/aboutus" element={<Aboutus/>} />
          <Route path="/team" element={<Team />} />
          <Route path="/product" element={<Product/>} />
          <Route path="/contactus" element={<Contactus/>} />
          <Route path="/software-develpoment" element={<Softwarede/>} />
          <Route path="/web-development" element={<Webdevlo/>} />
          <Route path="/mobile-application-development" element={<Mobappdev/>} />
          <Route path="/project-consultancy" element={<Projc/>} />
          <Route path="/domain-and-hosting" element={<Domhost/>} />
          <Route path="/digital_marketing" element={<Digmak/>} />
          <Route path="/Hardware-and-networking" element={<Hardnet/>} />
          <Route path="/shoots-and-tv-commercials" element={<Shoottv/>} />
          <Route path="/cctv-cameras" element={<CCTV/>} />
          <Route path="/portfolio" element={<Prot/>} />
          <Route path="/careers" element={<Careers/>} />
          <Route path="/gallery" element={<Gallery/>} />
          <Route path="/blog" element={<Blog/>} />
          <Route path="/support" element={<Support/>} />
          <Route path="/sitemap" element={<Sitemap/>} />
          <Route path="/real-estate-crm-leads-management-system" element={<Realcrm/>} />
          <Route path="/open-form-trading-with-qr-for-housing-building" element={<Oprnfo/>} />
          <Route path="/housing-scheme-software-in-pakistan" element={<Housins/>} />
          <Route path="/building-sales-management-software-sales" element={<Billing/>} />
          <Route path="/real-estate-software-in-pakistan" element={<Restsoft/>} />
          <Route path="/propertymanagementsystem" element={<Propmsystem/>} />
          <Route path="/construction-management-software" element={<Conststem/>} />
          <Route path="/accountsmanagementsoftware" element={<Accountm/>} />
          <Route path="/billing-management-software" element={<Billingm/>} />
          <Route path="/accountsmanagementsoftware" element={<Accountm/>} />
          <Route path="/land-acquisition-management-system" element={<Landac/>} />
          <Route path="/computerized-plot-balloting-for-housing-scheme" element={<CompPB/>} />
          <Route path="/Marketing_Dealers_Commission_Management" element={<Mkdcm/>} />
          <Route path="/biometric-registration-software" element={<Biom/>} />
          <Route path="/brand-sms" element={<Brandsms/>} />
          <Route path="/project-sales-recovery-follow-up" element={<Projsal/>} />
          <Route path="/apply-quaid-soft" element={<ApplyNow/>} />
          <Route path="*" element={<Home/>} /> 
      </Routes>
      <Popups/>
      <Toper/>
      <Whatsapp/>
    <Footer/>
    </>
  )
}

export default App