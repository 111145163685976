import {useEffect} from 'react'
import { Link } from 'react-router-dom'
import "./Careers.css"
const Careers = () => {
    useEffect(() =>{
        document.title="careers &#8211; Quaid Soft | ERP Software Company In Pakistan";
    },[]);
  return (
   <>
      <div className=" page-header py-2 mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center ">
            <h3 className="text-white animated slideInDown mb-4 mt-5">Careers</h3>
        </div>
    </div>
    <div className="container-xxl courses ">
<div  className=" row p-4 w-100"id="myGroup">
  <button className="p-2 m-2 btttt wow fadeInUp" data-wow-delay="0.1s" data-bs-toggle="collapse" href="#drp1" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="drp1">
    <i className="bi bi-plus-circle hh" ></i>    Software Support Engineer 
  </button>
  <div className="p-3 collapse color-black" id="drp1" data-bs-parent="#myGroup">
    <h4>Software Support Engineer Responsibilities</h4>
       <p className="ss">A Software Support Engineer is responsible for troubleshooting and resolving software-related issues reported by users, both internally and externally. Effective customer communication is crucial, involving interactions to understand problems and provide clear updates on issue status and resolutions. The role includes bug identification and documentation, collaborating closely with development teams for timely fixes. Additionally, Software Support Engineers contribute to training programs, create and maintain documentation, and play a role in quality assurance by testing software, monitoring performance, and optimizing functionality. This multifaceted role ensures the smooth operation of software applications and excellent user support. <br/>Familiar with software quality assurance.<br/><b>Job Type</b> Full Time<br/>
        <b>Job Location:</b> Lahore<br/>
        <b>Experience:</b> 1 Year <br/>
        Thank you for your interest in QuaidSoft. write email and send  <Link href="www.google.com" id="sse" target="_self" onclick="sendMail('sse'); return false"><i clas="blu">info@quaidsoft.com</i></Link> and we will get back to you promptly regarding your request.
 </p> 
<Link className="bttnn" to="/apply-quaid-soft">Apply Now</Link>
       
    </div>
  <button className="p-2 m-2 btttt wow fadeInUp" data-wow-delay="0.1s" data-bs-toggle="collapse" href="#drp2" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="drp2">
    <i className="bi bi-plus-circle hh"></i>  Marketing Executive (Female)
</button>
<div className="p-3  collapse color-black" id="drp2" data-bs-parent="#myGroup">
    <h4>Female Marketing Executive Responsibilities</h4>
    <p className="ss"> As a Female Marketing Executive, responsibilities include developing and executing marketing strategies to promote products or services. This involves market research, campaign management, and data analysis for effective decision-making. Collaboration with cross-functional teams, particularly in sales and product development, is essential. Communication skills are crucial for building relationships with clients, and contributing to a diverse and inclusive workplace may be a focal point. Overall, the role demands a combination of strategic thinking, creativity, and effective communication to achieve successful marketing outcomes. 
    <br/>Familiar with using the computer, internet and MS Office.<br/><b>Job Type</b> Full Time<br/>
    <b>Job Location:</b> Lahore<br/>
    <b>Experience:</b> 1 Year <br/>
    Thank you for your interest in QuaidSoft. write email and send  <Link to="www.google.com" id="mef" target="_self" onclick="sendMail('mef'); return false"><i className="blu"> info@quaidsoft.com</i></Link> and we will get back to you promptly regarding your request.</p> 

    <Link className="bttnn" to="/apply-quaid-soft">Apply Now</Link>
</div>
<button className="p-2 m-2 btttt wow fadeInUp" data-wow-delay="0.1s" data-bs-toggle="collapse" href="#drp3" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="drp1=3">
    <i className="bi bi-plus-circle hh"></i> Angularjs Developer
</button>
<div className="p-3  collapse color-black" id="drp3" data-bs-parent="#myGroup">
    <h4>AngularJS Developer Responsibilities</h4>
    <p className="ss">
        As an AngularJS Developer, the key responsibilities involve designing and implementing dynamic web applications using AngularJS framework. This includes developing and maintaining code, ensuring the responsiveness of applications, and collaborating with cross-functional teams for seamless integration. Attention to user experience and interface design, troubleshooting issues, and staying updated with industry trends are integral parts of the role. Overall, the position demands a strong grasp of AngularJS and front-end technologies to contribute to the development of robust and user-friendly web applications.
    
        <br/><br/><b>As JavaScript and AngularJS Developer, you will work across a broad range of different JavaScript, jQuery and AngularJS based UI projects. You’ll be expected to do the following: Plan, design and implement new UI Modules. Contribute constructively to brainstorms and idea sessions as necessary. To work on jQuery and AngularJS based UI projects.</b><br/><b>Job Type</b> Full Time<br/>
        <b>Job Location:</b> Lahore<br/>
        <b>Experience:</b> 1 Year <br/>
        Thank you for your interest in QuaidSoft. write email and send  <Link href="www.google.com" id="ad" target="_self" onclick="sendMail('ad'); return false"><i className="blus">info@quaidsoft.com</i></Link> and we will get back to you promptly regarding your request.</p> 
        <Link className="bttnn" to="/apply-quaid-soft">Apply Now</Link>
    </div>
<button className="p-2 m-2 btttt wow fadeInUp" data-wow-delay="0.1s" data-bs-toggle="collapse" href="#drp4" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="drp4">
    <i className="bi bi-plus-circle hh"></i> ASP .NET Developer
</button>
<div className="p-3  collapse color-black"  id="drp4" data-bs-parent="#myGroup">
    <h4>ASP.NET Developer Responsibilities</h4>
    <p className="ss">As an ASP.NET Developer, the core responsibilities revolve around designing and implementing web applications using the ASP.NET framework. This entails coding, maintaining application functionality, and collaborating with cross-functional teams for seamless integration. The role also involves a focus on user experience, interface design, troubleshooting, and staying abreast of industry trends. In essence, this position requires a strong command of ASP.NET and related technologies to contribute to the development of robust and efficient web applications. 
    
        <br/><br/><b>Design, develop and maintain both new and existing code, ranging from client-side development using JavaScript, HTML, and CSS, to server-side code using C# and ASP.NET MVC, Web API, Windows services, and ADO.NET for data persistence and retrieval.</b><br/><b>Job Type</b> Full Time<br/>
        <b>Job Location:</b> Lahore<br/>
        <b>Experience:</b> 1 Year <br/>
        Thank you for your interest in QuaidSoft. write email and send  <Link href="www.google.com" id="and" target="_self" onclick="sendMail('and'); return false"><i className="blu">info@quaidsoft.com</i></Link> and we will get back to you promptly regarding your request.</p> 
        <Link className="bttnn" to="/apply-quaid-soft">Apply Now</Link>
    </div>
<button className="p-2 m-2 btttt wow fadeInUp" data-wow-delay="0.1s" data-bs-toggle="collapse" href="#drp5" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="drp5">
    <i className="bi bi-plus-circle hh"></i>  Android Developer
</button>
<div className="p-3  collapse color-black"   id="drp5" data-bs-parent="#myGroup">
    <h4>Android Developer Responsibilities</h4>
    <p className="ss"> 
        As an Android Developer, the primary responsibilities include designing and implementing mobile applications using the Android platform. This involves coding, ensuring application performance, and collaborating with cross-functional teams for effective integration. Attention to user interface design, troubleshooting issues, and staying updated with the latest industry trends are key aspects of the role. In summary, the position requires a strong command of Android development and related technologies to contribute to the creation of innovative and user-friendly mobile application
    
        <br/><br/><b>Graduates from reputable universities and having good communication skills with at least 2 years of related professional experience are encouraged to apply for the position.</b><br/><b>Job Type</b> Full Time<br/>
        <b>Job Location:</b> Lahore<br/>
        <b>Experience:</b>  2 Year <br/>
        Thank you for your interest in QuaidSoft. write email and send  <Link href="www.google.com" id="add" target="_self" onclick="sendMail('add'); return false"><i className="blu">info@quaidsoft.com</i></Link> and we will get back to you promptly regarding your request.</p> 
        <Link className="bttnn" to="/apply-quaid-soft">Apply Now</Link>
    </div>
</div></div>


   </>
  )
}

export default Careers