import React from 'react'
import { Link } from 'react-router-dom';
import "./Hero.css";
const Hero = () => {
  return (
   <>
   <div class="container-fluid p-1 wow" data-wow-delay="0.1s">
    <div id="carouselExample" class="carousel slide " data-bs-ride="carousel">
        <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="2" aria-label="Slide 3"></button>
            <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="3" aria-label="Slide 4"></button>
            <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="4" aria-label="Slide 5"></button>
            <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="5" aria-label="Slide 6"></button>
            <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="6" aria-label="Slide 7"></button>
            <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="7" aria-label="Slide 8"></button>

          </div>
        <div class="carousel-inner"  >
            <div class="carousel-item active">
                
                <Link to="/real-estate-software-in-pakistan"  ><img class="w-100 d-block"  src={process.env.PUBLIC_URL + '/Images/Sliders/real-estate-software-in-pakistan.jpg'} title="real-estate-software-in-pakistan" alt="real-estate-software-in-pakistan"/></Link>
            </div>
            <div class="carousel-item">
                
                <Link to="/real-estate-crm-leads-management-system"  ><img class="w-100 d-block"  src={process.env.PUBLIC_URL + '/Images/Sliders/CRM-Leads Management System For Real Estate.jpg'} title="real-estate-software-in-pakistan" alt="CRM-Leads Management System For Real Estate"/></Link>
            </div>
            <div class="carousel-item">
                <Link to="/construction-management-software"  >   <img class="w-100 d-block " src={process.env.PUBLIC_URL + '/Images/Sliders/construction-management-software.jpg'} title="construction-management-software" alt="construction-management-software"/></Link>
            
            </div>
            <div class="carousel-item">
                <Link to="/accountsmanagementsoftware"  >    <img class="w-100 d-block " src={process.env.PUBLIC_URL + '/Images/Sliders/accountsmanagementsoftware.jpg'} title="accountsmanagementsoftware" alt="accountsmanagementsoftware"/></Link>
            </div>
            <div class="carousel-item">
                <Link to="brand-sms"  ><img class="w-100 d-block " src={process.env.PUBLIC_URL + '/Images/Sliders/brand-sms.jpg'} alt="brand-sms"/></Link>
        </div>
        <div class="carousel-item">
            <Link to="/real-estate-software-in-pakistan"  >  <img class="w-100 d-block " src={process.env.PUBLIC_URL + '/Images/Sliders/e-Real Web.jpg'}  title="e-Real Web" alt="e-Real Web"/></Link>
    </div>
    <div class="carousel-item">
        <Link to="/biometric-registration-software"  >   <img class="w-100 d-block " src={process.env.PUBLIC_URL + '/Images/Sliders/biometric-registration-software.jpg'} title="biometric-registration-software" alt="biometric-registration-software"/></Link>
</div>
<div class="carousel-item">
    <Link to="/open-form-trading-with-qr-for-housing-building"  > <img class="w-100 d-block " src={process.env.PUBLIC_URL + '/Images/Sliders/open-form-trading-with-qr-for-housing-building.jpg'} title="open-form-trading-with-qr-for-housing-building" alt="open-form-trading-with-qr-for-housing-building"/></Link>
</div>      
<button class="carousel-control-prev " type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
    <span class="carousel-control-prev-icon bg-dark " aria-hidden="true"></span>
    <span class="visually-hidden ">Previous</span>
</button>
<button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
    <span class="carousel-control-next-icon bg-dark" aria-hidden="true"></span>
    <span class="visually-hidden ">Next</span>
</button>
    </div>
    </div>
    </div>
   

   </>
  );
}

export default Hero;