import {useEffect} from 'react';
import "./Services.css";
import 'aos/dist/aos.css';
import AOS from 'aos';
import { Link } from 'react-router-dom';

const Services = () => {
    useEffect(() => {
        AOS.init();
      }, [])
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
    return (
    <>
     <div className="container-xxl py-01 ">
        <div className=" mx-w text-center mx-auto">
            <h3>Services</h3>    
        </div>
<div className="mob1" >
  <div className="max-auto servicesbox p-4 " data-aos="zoom-out"  data-aos-duration="1000" >
    <div className="fa fa-laptop iconn "></div>
        <h5 className='hea5'>Software Development</h5>
        <p className="f-s">Quaid Soft takes pride in offering as First Real Estate Customized and tailored Software Development Solutions for the Real Estate sector.....</p>
        <span className="ccor"><Link onClick={scrollToTop} className="a1" to="/software-develpoment">Read More..</Link></span>

    </div>
   <div className="max-auto servicesbox p-4 " data-aos="zoom-out" data-aos-duration="1000">
        <div className="fas fa-globe iconn " ></div>    
        <h5 className='hea5'>Web Development</h5>
            <p   className=" f-s">Quaid Soft specialize in the development of professional and dynamic websites, web portals, and comprehensive online solutions tailored....</p>
            <span className="ccor"><Link onClick={scrollToTop}  className="a1" to="/web-development">Read More..</Link></span>

        </div>
    
   <div className=" max-auto servicesbox p-4  " data-aos="zoom-out" data-aos-duration="1000">
            <div className="fas fa-mobile iconn "></div>
                <h5 className='hea5'>Mobile App Development</h5>
                <p   className=" f-s">Revolutionize your Real Estate experience with Quaid Soft’s cutting-edge mobile application development tailored specifically for the industry....</p>
                <span className="ccor"><Link onClick={scrollToTop}  className="a1" to="/mobile-application-development">Read More..</Link></span>
            </div>


</div>   

<div className="mob1" >
   <div className="max-auto  servicesbox p-4  " data-aos="zoom-out"  data-aos-duration="1000" >
    <div className="fab fa-pushed iconn" ></div>
        <h5 className='hea5'>Project Consultancy</h5>
        <p className=" f-s">With a wealth of 15 years of experience and a robust portfolio boasting over 600 satisfied clients across Pakistan, our Real Estate Project......</p>
        <span className="ccor"><Link onClick={scrollToTop}  className="a1" to="/project-consultancy">Read More..</Link></span>

    </div>
  

  <div className="max-auto servicesbox p-4  " data-aos="zoom-out" data-aos-duration="1000" >
        <div className="fa fa-server iconn " ></div>
            <h5 className='hea5'>Domain & Hosting</h5>
            <p   className=" f-s">To accomplish with your DNS and online visibility needs? At Quaid Soft, We are available as domain registrar & PKNIC reseller in Pakistan, Providing......</p>
            <span className="ccor"><Link onClick={scrollToTop}  className="a1" to="/domain-and-hosting">Read More..</Link></span>

        </div>
       <div className="max-auto servicesbox p-4 "  data-aos="zoom-out" data-aos-duration="1000" >
            <div className="fas fa-bullhorn iconn " ></div>
                <h5 className='hea5'>Digital Marketing</h5>
                <p   className=" f-s">Welcome to Quaid Soft ®, a distinguished leader in software development, now proudly leveraging our expertise in Digital Marketing Services......</p>
                <span className="ccor"><Link onClick={scrollToTop}  className="a1" to="/Digital_Marketing">Read More..</Link></span>

            </div>
       


</div>  
<div  className="mob1">
    <div className="max-auto servicesbox p-4 " data-aos="zoom-out"  data-aos-duration="1000">
     <div className="fa fa-audio-description iconn " ></div>
         <h5 className='hea5'>Shoots & Tv Commercial</h5>
         <p   className=" f-s">To accomplish with your DNS and online visibility needs? At Quaid Soft, We are available as domain registrar & PKNIC reseller in Pakistan, Providing....</p>
         <span className="ccor"><Link onClick={scrollToTop}  className="a1" to="/shoots-and-tv-commercials">Read More..</Link></span>
        </div>

     <div className="max-auto servicesbox p-4 " data-aos="zoom-out"  data-aos-duration="1000">
        <div className="fa fa-youtube-play iconn " ></div>
            <h5 className='hea5'>CCTV Cameras</h5>
            <p   className=" f-s">Quaid Soft offering interactive TV Add commercials Service for Developers, Builders, Construction & Other Companies for the top quality marketing of their .....</p>
            <span className="ccor"><Link onClick={scrollToTop}  className="a1" to="/cctv-cameras">Read More..</Link></span>
        </div>
 
            <div className=" max-auto servicesbox p-4  " data-aos="zoom-out"  data-aos-duration="1000">
                <div className="fas fa-building iconn" ></div>
                    <h5 className='hea5'>Hardware & Networking</h5>
                    <p   className="f-s">As enterprise networks are converging, organizations are tasked with intelligently handling more network traffic, while at the same time, extending the same functionality....</p>
                    <span className="ccor"><Link onClick={scrollToTop}  className="a1" to="/hardware-and-networking">Read More..</Link></span>
                </div>

 </div>    
 </div> 
    </>
  );
}

export default Services;