import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import * as bootstrap from 'bootstrap';

const Popups = () => {
  const [isVisible, setIsVisible] = useState(true);
  const popupRef = useRef(null);

  useEffect(() => {
    let intervalId;

    if (isVisible) {
      intervalId = setInterval(() => {
        const carousel = new bootstrap.Carousel(document.getElementById('header-carousel1'));
        carousel.next();
      }, 5000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isVisible]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsVisible(false);
      }
    };

    if (isVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisible]);

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <>
      {isVisible && (
        <div className="popup-container" ref={popupRef}>
          <div class="container-fluid p-1 wow" data-wow-delay="0.1s">
            <div id="carouselExample" class="carousel slide d-f " data-bs-ride="carousel">
              <div className='w-100'>
                <Link class="bttnn1 btn-square btn-outline-light me-1" target="_blank" to="https://api.whatsapp.com/send?phone=+923214812006&text=Hello%21%20QuaidSoft"><i class="bi bi-whatsapp"></i></Link>
                <Link class="bttnn1 btn-square btn-outline-light me-1" target="_blank" to="https://www.facebook.com/quaidsofttech"><i class="fab fa-facebook-f"></i></Link>
                <Link class="bttnn1 btn-square btn-outline-light me-1" target="_blank" to="https://www.linkedin.com/company/quaid-soft/about/"><i class="fab fa-linkedin-in"></i></Link>
                <Link class="bttnn1 btn-square btn-outline-light me-1" target="_blank" to="https://www.instagram.com/quaidsoft/"><i class="fab fa-instagram"></i></Link>
                <Link class="bttnn1 btn-square btn-outline-light me-1" target="_blank" to="https://twitter.com/QuaidSoft"><i class="fab fa-twitter"></i></Link>
                <Link class="bttnn1 btn-square btn-outline-light me-1" target="_blank" to="https://youtube.com/@quaidsoft"><i class="fab fa-youtube"></i></Link>
              </div>
              <div className='w-50'>
                <button class="bttnn2 m-l" onClick={handleClose}>X</button>
              </div>
            </div>
            <hr />
            <div class="modal-body carousel slide carousel-fade mt-2" id="header-carousel1" data-bs-ride="carousel">
              <div class="carousel-inner">
                <div class="carousel-item  active">
                  <Link to="real-estate-software-in-pakistan" ><img class="w-100  minsl d-block"  src={process.env.PUBLIC_URL + '/Images/Sliders/real-estate-software-in-pakistan.jpg'} title="real-estate-software-in-pakistan" alt="real-estate-software-in-pakistan"/></Link>
                </div>
                <div class="carousel-item">
                  <Link to="real-estate-crm-leads-management-system" ><img class="w-100 minsl d-block " src={process.env.PUBLIC_URL + '/Images/Sliders/CRM-Leads Management System For Real Estate.jpg'} title="CRM-Leads Management System For Real Estate" alt="CRM-Leads Management System For Real Estate"/></Link>
                </div>
                <div class="carousel-item ">
                  <Link to="construction-management-software"><img class="w-100 minsl d-block " src={process.env.PUBLIC_URL + '/Images/Sliders/Property-software-real-estate-software-Pakistan-property-management-software-Pakistan-sales-management-software-Pakistan-property-management-software-real-estate-software.jpg'} title="construction-management-software" alt="construction-management-software"/></Link>
                </div>  
              </div>
              <button class="carousel-control-prev" type="button" data-bs-target="#header-carousel1" data-bs-slide="prev">
                <span class="carousel-control-prev-icon bg-dark" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#header-carousel1" data-bs-slide="next">
                <span class="carousel-control-next-icon bg-dark" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Popups;
